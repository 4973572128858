import {
  CommerceActivityTrackingEventProductMetadata,
  ConvergeSdk,
} from "@seeka-labs/converge";
import { WaitListFormValues } from "~components/events/waitlist/interface";
import {
  GetOrderJobStatusQuery,
  OrderType,
} from "~graphql/typed-document-nodes";

export function seekaTrackCheckout(
  converge: ConvergeSdk | undefined,
  order: GetOrderJobStatusQuery["getOrderJobStatus"]["order"],
  currencyCode: string
) {
  if (!converge) {
    return;
  }

  if (order.orderType !== OrderType.Default) {
    return;
  }

  const groupedProducts: {
    [key: string]: CommerceActivityTrackingEventProductMetadata;
  } = {};

  order?.lineItems?.edges?.forEach(({ node: lineItem }) => {
    const seekaProduct = {
      variantIdentifier:
        lineItem.ticketType?.id ??
        lineItem.membershipType?.id ??
        lineItem.eventAddon?.id ??
        lineItem.membershipAddon?.id,
      variantName:
        lineItem.ticketType?.name ??
        lineItem.membershipType?.membership?.name ??
        lineItem.eventAddon?.name ??
        lineItem.membershipAddon?.name,
      quantity: lineItem.quantity,
      unitPrice: lineItem.price,
      productIdentifier: order.event?.id ?? order.membership?.id,
      productName: order?.event?.title ?? order.membership?.name,
    };

    const groupingKey = `${seekaProduct.variantIdentifier}-${
      seekaProduct.variantName
    }-${seekaProduct.unitPrice}-${lineItem.seatZone ?? "empty"}`;

    // Check if a group already exists for this key
    if (!groupedProducts[groupingKey]) {
      groupedProducts[groupingKey] = { ...seekaProduct };
      return;
    }

    // Accumulate quantity for matching groups
    groupedProducts[groupingKey].quantity += seekaProduct.quantity;
  });

  void converge?.track?.initiateCheckout({
    currencyCode,
    checkoutIdentifier: order?.id,
    products: Object.values(groupedProducts),
  });
}

export function seekaTrackRegistration(
  converge: ConvergeSdk | undefined,
  values: WaitListFormValues
): void {
  void converge?.identity?.mergeProfile({
    email: [values.email],
    phone: [values.phoneNumber],
    firstName: [values.firstName],
    lastName: [values.lastName],
  });
  void converge?.track?.lead({
    sourceContentName: "Registration form",
  });
}

export function seekaTrackWaitlistSignup(
  converge: ConvergeSdk | undefined,
  event: { id: string; name: string },
  values: WaitListFormValues,
  ticketTypes: { id: string; name: string; quantity?: number }[]
): void {
  void converge?.identity?.mergeProfile({
    email: [values.email],
    phone: [values.phoneNumber],
    firstName: [values.firstName],
    lastName: [values.lastName],
  });
  for (const ticketType of ticketTypes) {
    void converge?.track?.addToWishlist({
      productIdentifier: event.id,
      productName: event.name,
      variantIdentifier: ticketType.id,
      variantName: ticketType.name,
      quantity: ticketType.quantity,
    });
  }
}
